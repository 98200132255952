.header {
  display: flex;
  margin-right: 12px;
  column-gap: 12px;
  align-items: center;
}

.btn {
  border-radius: 4px;
  white-space: pre-wrap;
  text-align: left;
}