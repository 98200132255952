@use 'styles/theme/theme' as theme;

.text, .dates {
  width: 50px;
  display: flex;

  @media only screen and (max-width: 850px) {
    justify-content: flex-end;
    width: 100%;
  }
}
