@import 'styles/theme/theme';

.icon {
  margin-right: 4px;
  position: relative;
  bottom: 1px;
}

.separatorItem {
  & + & {
    margin-left: 8px;
  }
}

.separatorListContainer {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 100%;
  line-height: 1;
}

.redMask {
  display: flex;

  .maskIcon {
    margin: 0 2px 0 -3px;
    height: 16px;
    width: 16px;
  }
}

.italic {
  font-style: italic;
}

.entityImage {
  background-color: $whiteSmoke;
  border-radius: 50%;
  overflow: hidden;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.productionDateSummary {
  margin-bottom: 8px;
  display: flex;
  .year {
    margin-right: 10px;
  }
  .dates {
    margin-right: 5px;
  }
}

.isCancelled {
  a {
    text-decoration: line-through !important;
  }
}

.lowerCase {
  text-transform: lowercase;
}
