@use 'styles/theme/theme';

.header {
  display: flex;
  flex-direction: column;
  column-gap: 8px;
}

.headerDetails {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > span {
    display: inline;
    vertical-align: baseline;
  }

  & > div {
    display: inline-flex;
  }

  & > span, & > div {
    &:not(:last-child)::after {
      content: '|';
      margin: 0 8px;
      width: 1px;
      font-style: normal;
      font-size: theme.$font-size-12;
    }
  }
}

.composer {
  display: inline-flex;
  align-items: flex-start;
}

.musicalWorkCount {
  &::before {
    content: '|';
    margin: 0 8px;
    width: 1px;
    height: 12px;
    font-style: normal;
    font-size: 12px;
  }
}

.duration {
  max-width: 86px;
}