.period {
  max-width: 123px;
  width: 123px;
  align-items: baseline;
  justify-content: flex-end;
  display: flex;

  @media (max-width: 800px) {
    width: auto;
    max-width: fit-content;
  }
  &__link {
    min-width: auto;
    align-items: flex-start;
    @media (max-width: 1024px) {
      width: 70px;
      white-space: break-spaces;
    }

    @media (max-width: 800px) {
      width: auto;
      white-space: normal;
    }

    &.mwLink {
      @media (max-width: 1024px) {
        width: auto;
        white-space: break-spaces;
      }
    }
  }
  &__multiRole {
    flex-direction: column;
    align-items: flex-end;
    margin-right: 20px;
    @media (max-width: 800px) {
      padding-right: 0px;
    }
  }
  &__dateCount {
    margin-right: 13px;
  }
}

.period .mask {
  margin-left: 2px;
}

.mask {
  .icon {
    margin-top: 1px;
  }
}

@media only screen and (max-width: 960px) {
  .period {
    justify-content: flex-start;
    &__dateCount {
      display: none;
    }
  }
}
