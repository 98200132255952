@use 'styles/theme/theme' as theme;

.venueItems {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 24px;
  padding: 0;
  margin: 0;
}

.venueItem {
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
  list-style: none;
}

.noVenue {
  grid-template-columns: minmax(0, 1fr);
}

.venueWrapper {
  &:hover .venue>* {
    color: theme.$blue;
    text-decoration: underline;

    svg {
      fill: theme.$blue;
    }
  }

  &:hover .venue>.venue__countryCity {
    color: theme.$primaryGrey;
    text-decoration: none;
  }

  .venue {
    display: flex;
    flex-direction: column;
    width: 100%;
    white-space: normal;
    line-height: theme.$line-height-16;
    gap: 8px;

    &.cancelled {
      text-decoration: line-through;
    }

    &__countryCity {
      display: flex;
      align-items: center;

      &.maskText {
        cursor: pointer;
        @include theme.blurText;
      }

      &.proVenueCountryCity {
        font-size: 12px;
      }
    }

    &__name {
      display: flex;
      gap: 4px;
      margin-left: 15px;
      word-break: break-word;

      &.maskText {
        cursor: pointer;
        @include theme.blurText;
      }

      &.proVenueName {
        font-size: 12px;

        span {
          svg {
            height: 14px;
            width: 14px;
          }
        }
      }
    }

    &__locationIcon {
      fill: theme.$black;
      flex-shrink: 0;

      &:hover {
        fill: theme.$blue;
      }
    }

    &__stageName {
      margin-left: 48px;

      &.maskText {
        cursor: pointer;
        @include theme.blurText;
      }
    }
  }
}

.datesList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}