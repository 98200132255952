@import 'styles/theme/theme';

.root {
  display: flex;
  flex-direction: column;
}

.performanceViewInfo {
  text-align: center;
  margin-bottom: 16px;
}

.worksSelector {
  text-align: center;
  margin-top: 8px;
}