@import 'styles/theme/theme';

.prodWorksInfo {
  .titleWrapper {
    display: flex;
    margin-bottom: 8px;

    @media only screen and (max-width: 600px) {
      padding: 12px 12px 0px 12px;
    }

    .title {
      color: $black;

      @media only screen and (max-width: 600px) {
        display: none;
      }
    }

    .total {
      margin-left: 4px;

      &::before {
        content: '•';
        font-weight: $font-weight-bold;
        margin-right: 4px;
      }

      @media only screen and (max-width: 600px) {
        display: none;
      }
    }

    .programTag {
      margin-left: 12px;
      text-transform: uppercase;

      @media only screen and (max-width: 600px) {
        display: none;
      }
    }
  }

  .worksWrapper {
    display: flex;
    padding-left: 16px;

    .works {
      width: 100%;
      margin-top: 3px;

      &.cancelled {
        text-decoration: line-through;
      }

      .prodTitle {
        margin-bottom: 7px;
        display: block;
      }

      .worksAndTags {
        display: flex;
        align-items: baseline;
        padding: 14px;
        border-bottom: 1px solid $borderGrey;

        .work {
          flex: 1;
          .workLink {
            &:hover > * {
              color: $blue;
            }
          }

          .originalName,
          .name,
          .composer {
            margin-right: 3px;

            &:hover {
              color: $blue;
            }
          }
        }

        .tags {
          display: flex;
          flex-direction: column;
          margin-right: 8px;
          .tag {
            display: flex;
            justify-content: flex-end;
            .workTag {
              width: 70%;
              margin-right: 4px;
              text-align: right;
              text-transform: uppercase;
            }
            .stageTag {
              text-align: right;
            }
          }
        }

        .excerpt {
          margin-top: 4px;
          margin-left: 8px;

          &::before {
            content: '•';
            font-weight: $font-weight-bold;
            margin-right: 4px;
          }
        }

        &:last-child {
          .work {
            margin-bottom: 0px;
          }
        }
      }
    }

    @media only screen and (max-width: 600px) {
      flex-direction: column;
      padding: 12px;
      margin-bottom: 40px;

      .works {
        margin-top: 0px;
      }
    }
  }
}

.isBlur{
  filter: blur(4px);
}