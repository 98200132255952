@use 'styles/theme/theme' as theme;

.langSubtitles {
  display: flex;
  align-items: center;
  &::before {
    margin-left: 8px;
    content: '';
    height: 12px;
    border-right: 1px solid theme.$primaryGrey;
    @media only screen and (max-width: 960px) {
      border-right: none;
      margin-left: 0px;
    }
  }
  & + & {
    margin-right: 5px;
  }
  .languages {
    margin-right: 4px;
  }

  .languages, .subtitles {
    display: flex;
    align-items: center;
    margin-left: 15px;
    .icon {
      color: theme.$primaryGrey;
      margin-right: 4px;
    }

    @media only screen and (max-width: 960px) {
      margin-left: 0px;
      &::before {
        margin-right: 0;
        border-right: none;
      }
    }

    @media only screen and (max-width: 600px) {
      margin-top: 0px;
    }
  }
}
