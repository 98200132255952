@use 'styles/theme/theme' as theme;

.parent {
  display: flex;
  flex-direction: column;
  margin: 16px;
  row-gap: 16px;
}

.quickPreview {
  width: 375px !important;
  padding: 0px 10px 10px 10px;
  &__header {
    background-color: theme.$white;
    position: sticky;
    top: 0px;
    padding-top: 10px;
    display: flex;
    align-items: center;
    z-index: 1;
    &_close {
      margin-right: 4px;
    }
  }
}

.modalContent {
  overflow-y: initial;
  height: 100%;
}

.modalRoot {
  padding: 0;
}

.modalRoot .modalContainer {
  width: 375px;
  float: right;
  height: 100%;
}
