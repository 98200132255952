@import 'styles/theme/theme';

.sectionLinkWrapper {
  display: flex;
  justify-content: space-between;
}

.titleWrapper {
  display: flex;
  align-items: baseline;
}

.title {
  color: $primaryGrey;
  margin-bottom: 16px;
  display: inline-block;
  flex-wrap: wrap;
  align-items: center;
  white-space: nowrap;
  & >svg {
    vertical-align: middle;
  }

  &__link {
    &:hover {
      color: $blue;
      svg {
        fill: $blue;
      }
    }
  }
}

.count {
  &:before {
    content: "•";
  }
  margin-left: 4px;
}

.footerTitle {
  color: inherit;
  margin: 8px 0 0;
}

.withoutMargin {
  margin: 0;
}

@media only screen and (max-width: 1200px) {
  .indicator {
    width: 10px;
    height: 10px;
    margin-left: -16px;
    background-color: $primaryGrey;
    margin-right: 6px;
  }
}
