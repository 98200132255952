@use 'styles/theme/theme' as theme;


.workDropDown {
  cursor: pointer;
  margin-bottom: 17px;

  &__selectedWork {
    margin-left: 4px;
  }

  &__expandIcon {
    fill: theme.$blue !important;
  }
}

.title {
  padding: 8px 16px;
}

.popover {
  width: 230px;
}

.worksList {
  padding: 12px 16px;
  &__item {
    display: flex;
    cursor: pointer;
    margin-bottom: 12px;
    justify-content: space-between;
  }
}

.divider {
  height: 1px;
  background-color: theme.$tertiaryGrey;
  width: 100%;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  &::before {
    content: "(";
    color: theme.$secondaryGrey;
  }
  &::after {
    content: ")";
    color: theme.$secondaryGrey;
  }
  .tag {
    .workTag {
      margin-right: 4px;
      text-transform: uppercase;
    }
  }
}
