@use 'styles/theme/theme' as theme;

.parent {
  & > :not(:first-child) {
    margin-top: 8px;
  }
}

.collapseSection {
  max-width: 264px;
  width: 264px;
  display: flex;
  gap: 8px;
  align-items: baseline;

  @media (max-width: 1024px) {
    max-width: 230px;
  }
}

.location {
  max-width: 120px;
  width: 120px;
  display: flex;
  gap: 4px;

  @media (max-width: 850px) {
    max-width: 90px;
    width: 90px;
  }
}

.expandedSection {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
  width: 264px;

  &__title {
    display: flex;
  }
  &.quickPreview {
    width: 100%;
    margin-top: 0px;
  }
}

.indent {
  text-indent: -15px;
  padding-left: 15px;
  &.venue {
    word-break: break-word;
    white-space: normal;
  }
}

.innerIndent {
  text-indent: 15px;
  margin-top: 4px;
}

.venueItems {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: 24px;
  padding: 0;
  margin: 0;
}

.venueItem {
  display: grid;
  align-items: flex-start;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 16px;
  list-style: none;
    & > div > span {
    font-size: 12px;
  }
}

.datesList {
  margin-top: 2px;
  div {
    span {
      font-size: 12px;
    }
  }
}