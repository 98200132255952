@use 'styles/theme/theme' as theme;

.titleType {
  margin-top: 20px;
  margin-bottom: 12px;

  @media only screen and (max-width: 600px) {
    display: block;
  }
}

.listItems {
  padding: 0;
  margin: 0;

  &__item {
    list-style: none;

    & + & {
      margin-top: 10px;
    }
  }
}

.castCrewContent {
  display: grid;
  grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);

  & + & {
    margin-top: 10px;
  }
}

.title {
  padding-right: 30px;
  text-align: right;
  margin-top: 8px;
}

.crewDetails {
  display: flex;
  align-items: flex-start;
  grid-gap: 8px;
  cursor: pointer;

  @media (min-width: 600px) {
    &:hover .titleLink:not(.textOnly) {
      text-decoration: underline;

      .titleLink_title {
        color: theme.$blue;
      }

      .titleLink_icon {
        visibility: visible;
      }

      svg {
        fill: theme.$blue;
      }
    }
  }

  &_disableHref {
    pointer-events: none;
  }

  &__image {
    width: 44px;
    height: 44px;
    box-sizing: border-box;
    border: 1px solid theme.$borderGrey;

    .imageLetters {
      color: theme.$tertiaryGrey;
    }
  }

  &__info {
    width: calc(100% - 44px);
    padding-left: 8px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-top: 8px;

    .titleLink {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &_icon {
        visibility: hidden;
        flex-shrink: 0;
        margin-left: auto;

        @media (max-width: 600px) {
          visibility: visible;
        }
      }

      &_title {
        white-space: normal;
        overflow-wrap: break-word;
        text-decoration: underline transparent;
      }
    }

    &_subtitle {
      color: theme.$secondaryGrey;
      padding-left: 16px;
      margin-right: 20px;
    }

    &_dates {
      padding-left: 32px;
      display: flex;
      text-indent: -16px;
      margin-right: 20px;
      white-space: normal;
      overflow-wrap: break-word;

      &.cancelled {
        text-decoration: line-through;
      }
    }
  }
}

.archived {
  @include theme.blurText;
  cursor: pointer;
  overflow: hidden;
}

.nonLinkable {
  cursor: default;
}
