@use 'styles/theme/theme' as theme;

.root {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .dates {
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;
    align-items: center;

    &__month {
      padding-right: 10px;
      text-indent: -16px;
    }

    &__item {
      display: flex;
      align-items: flex-end;
      gap: 2px;
      padding-right: 3px;
      .dateTime {
        color: theme.$primaryGrey;
        font-weight: theme.$font-weight-regular;
      }
    }

    &__itemSubText {
      line-height: 1.4;
    }

    .cancelled {
      text-decoration: line-through;
    }
  }
}

.dummyYear {
  visibility: hidden;
}

.dateCancelledPopover {
  padding: 5px;
  background: theme.$red-light-01;
  font-weight: theme.$font-weight-medium;
  border-radius: none;
  color: theme.$secondaryGrey;
}

.maskText {
  cursor: pointer;
  @include theme.blurText;
}
